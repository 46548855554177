nav {

}

li {
  display: inline-flex;
}

.topbar {


}

@media (max-width: 1150px) {
    .topbar {
        top: 0;
        position: fixed;
        width: 100%;
        padding: 0;
        z-index: 2;
    }
}

.logoContainer {
    z-index: 2;
    position: fixed;
    top: 10px;
    left: 30px;
    padding: 1rem;
    max-width: 130px;
    box-shadow: 0 0px 15px #cacaca;
    border-radius: 75px;
    background: white;
}

.user {
    text-align: right;
    margin-left: 0.5rem;
    margin-right: 2%;
    color: white;
}

.user > h4 {
    margin-top: 1.5rem;
}

.user > h4:hover {
    cursor: pointer;
}

TopbarInput {
}

.social-media-container {
    display: inline-flex;
}

.social-media {
    margin: 0 6px;
    font-size: medium;
    background: #fff;
    border-radius: 25px;
    padding: 0.3rem;
    color: #f3bf57;
    box-shadow: 2px 2px 10px #cacaca;
    transition: all 0.5s ease;
}

.social-media:hover {
    cursor: pointer;
}

.social-media-low {
    transform: translateY(95%);
}

@media (max-width: 1150px) {
    .social-media-low {
        transform: unset;
    }
}

.social-media-low-link {

}
