@import '../node_modules/animate.css/animate.min.css';

@font-face {
  font-family: confortaa-regular;
  src: url('assets/fonts/Comfortaa_Regular.ttf');
}

@font-face {
  font-family: adequate;
  src: url('assets/fonts/Adequate-ExtraLight.ttf');
}

@font-face {
  font-family: quesha;
  src: url('assets/fonts/Quesha.ttf');
}

@font-face {
  font-family: montserrat-black;
  src: url('assets/fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: montserrat-bold;
  src: url('assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: montserrat-semibold;
  src: url('assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: montserrat-extrabold;
  src: url('assets/fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: montserrat-medium;
  src: url('assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: montserrat-regular;
  src: url('assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: montserrat-light;
  src: url('assets/fonts/Montserrat-Light.ttf');
}

* {
  transition: all 0.2s ease-in-out;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: montserrat-semibold, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.p-dialog {
  background: white !important;
  box-shadow: 1px 1px 5px grey !important;
  border-radius: 8px;
}

.p-dialog-mask {
  background: rgba(0,0,0,0.4);
}


button:hover {
  cursor: pointer
}

button:active {
  opacity: 0.8;
}

button:focus {
  outline:0;
}

.slick-slide div {
  outline: none !important;
}

.selected-procedure {
  border: 2px solid #dbb89f;
  box-shadow: 0px 0px 8px #dbb89f;
  height: 130px;
  border-radius: 12px;
}

.procedure-card {
  box-shadow: 0px 0px 10px grey;
  height: 130px;
  border-radius: 12px;
}

.MuiTouchRipple-rippleVisible {
  display: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiDialog-paper {
  width: 100%;
  max-width: 500px !important;
}
@media (max-width: 600px) {
  .MuiDialog-paper {
    margin: 12px !important;
  }
}


.MuiDialog-paperScrollPaper {
  border-radius: 25px !important;
}

.p-datatable-row, .p-datatable-thead {
  border: 1px solid #cacaca;
  font-family: montserrat-light;
  color: rgba(0,0,0,0.5);
}

.line-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.action-header {
  width: 250px;
  text-align: center;
}
@-webkit-keyframes growing /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes growing {
  0% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  80% {
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -ms-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
}
.growing {
  -webkit-animation: growing 1s linear infinite;
  -moz-animation: growing 1s linear infinite;
  -ms-animation: growing 1s linear infinite;
  -o-animation: growing 1s linear infinite;
  animation: growing 1s linear infinite;
}

.lgpd-link {
  color: #0645AD !important;
  font-size: 12px;
  font-family: montserrat-regular;
  margin-bottom: 5px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  border-bottom: 1px solid #cacaca !important;
}

.MuiAccordion-root:before {
  background-color: none !important;
}

.MuiAccordionSummary-content {
  font-size: 15px;
  font-family: montserrat-regular;
  color: #0645AD !important;
  cursor: pointer;
}

.MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
}

.row-highlight {
  background-color: #bd997f4d;
}

.add-btn:hover {
  opacity: 0.8;
  border-radius: 100%;
  transition: all 0.6s ease;
}
