.ant-pagination-item-active, .ant-pagination-item-active a {
    border-color: #BD997F;
    color: black !important;
}

.ant-pagination-item:hover, .ant-pagination-item:hover a, .ant-pagination-next:hover button, .ant-pagination-prev:hover button {
    border-color: #BD997F !important;
    color: #BD997F !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #BD997F !important;
}

.ant-pagination-item-active {
    border-color: #BD997F !important;
}

.ant-pagination-item a {
    color: #BD997F;
    font-family: montserrat-medium;
    font-size: 12px;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: #f1efef !important;
}

.ant-table {
    font-size: 12px !important;
}
